<template>
  <div v-show="isActive" style="padding: 16px">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: {required: true},
    selected: {default: false},
    prefix: {required: false}
  },
  data() {
    return {
      tabName: '',
      isActive: this.selected
    }
  },
  created() {
    this.tabName = this.name
    this.isActive = this.selected
  }
}
</script>
