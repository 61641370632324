<template>
  <div class="top-wrapper" ref="topWrapper">
    <MainImage/>
    <div class="main-content-box">
      <div class="container social-links-wrap show-desktop-important">
        <div class="social-links" v-if="$config">
          <a :href="$config.twitterLink" target="_blank" class="link twitter"></a>
          <a :href="$config.facebookLink" target="_blank" class="link facebook"></a>
          <a :href="$config.instagramLink" target="_blank" class="link instagram"></a>
        </div>
        <div v-else></div>
      </div>
      <v-container v-if="!$store.state.device.isMobile">
        <div class="main-content">
          <div class="center-content">
            <h1 class="slide-title" v-html="$texts.tagline">
            </h1>
            <slot/>
          </div>
        </div>
      </v-container>
      <div v-else></div>
    </div>
  </div>
</template>

<script>
import MainImage from './MainImage'
export default {
  components: {MainImage},
}
</script>

<style src="./top-section.scss" lang="scss"></style>
