<template>
  <div>
    <landing-page/>
  </div>

</template>

<script>
import LandingPage from '@/components/LandingPage/LandingPage.vue'

const titles = {
  tripening: `Things to do, Tickets, Hotels and Flights | ${new Date().getFullYear()} | Tripening`,
  hnm: `Hotels | ${new Date().getFullYear()} | HotelsNearMe`,
}

export default {
  head () {
    return {
      title: titles[this.$store.state.siteBrand],
      meta: [
        {
          'property': 'twitter:card',
          'name': 'twitter:card',
          'content': 'summary',
        },
        {
          'property': 'twitter:site',
          'name': 'twitter:site',
          'content': '@tripening',
        },
        {
          'property': 'twitter:image',
          'name': 'twitter:image',
          'content': `${this.siteHost}/img/default-site-img.jpg`,
        },
        {
          'property': 'twitter:title',
          'name': 'twitter:title',
          'content': this.$store.state.siteBrand,
        },
        {
          'property': 'og:image',
          'name': 'og:image',
          'content': `${this.siteHost}/img/default-site-img.jpg`,
        },
        {
          'property': 'og:title',
          'content': 'All Your Travel Needs. Anytime. Anywhere.',
        },
        {
          'property': 'og:url',
          'content': `${this.siteHost}${this.$route.fullPath}`,
        },
        {
          'property': 'og:image',
          'content': `${this.siteHost}/img/default-site-img.jpg`,
        },
        { 'hid': 'description',
          'name': 'description',
          'content': this.$texts.brand_name + ' is a new way to plan your trip. Using advanced Artificial Intelligence and sourcing tours from all over the world, you will get the best travel experience.' + `${this.$texts.brand_slug === 'tripening' ? 'Happy Tripening!': ''}`
        },
        {
          'property': 'og:description',
          'content': this.$texts.brand_name + ' is a new way to plan your trip. Using advanced Artificial Intelligence and sourcing tours from all over the world, you will get the best travel experience.' + `${this.$texts.brand_slug === 'tripening' ? 'Happy Tripening!' : ''}`
        },
      ],
    }
  },
  data() {
    return {
      activeTab: this.$store.state.initHotels ? '#hotels' : '#attractions'
    }
  },
  computed: {
    initHotels() {
      return this.$store.state.initHotels
    },
    siteHost() {
      return this.$store.state.siteHost
    },
  },
  components: {
    LandingPage
  },
  mounted() {
    csdm('send', 'home')
  },
  jsonld() {
    return {
      '@context': 'http://schema.org',
      '@type': 'TravelAgency',
      name: this.$texts.brand_name,
      description: 'Everything You Need In One Place!',
      image: `${this.siteHost}/img/logo.svg`
    }
  }
}

</script>
