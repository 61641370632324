<template>
  <div class="tabs-wrapper">
    <div class="tabs">
      <ul class="tabs_list" v-if="tabsInfo.length">
        <li class="tabs_list-item" v-for="(tab, index) in tabsInfo" :key="index">
          <div
            class="tab-wrapper"
            :class="{'active': tab.isActive }"
            @click="tab.isActive ? null : selectTab(tab)"
          >
            <div class="tabs-bg tabs-bg--tabs"></div>
            <div class="tab_body">
              <img src="/img/icons/fa-landmark.svg" width="18" height="18" alt="attractions"  v-if="tab.prefix === 'attractions'">
              <img src="/img/icons/fa-hotel.svg" alt="hotels"  width="18" height="18" v-if="tab.prefix === 'hotels'">
              <img src="/img/icons/fa-calendar-star-light.svg"  width="18" height="18" alt="events" v-if="tab.prefix === 'events'">
              <img src="/img/icons/fa-plane-departure.svg"  width="18" height="18" alt="events" v-if="tab.prefix === 'flights'">
              <span class="tab_title">{{tab.name}}</span>
            </div>
          </div>
        </li>
      </ul>
      <ul class="tabs_list" v-else>
        <li class="tabs_list-item">
          <div
            class="tab-wrapper active"
          >
            <div class="tabs-bg tabs-bg--tabs"></div>
            <div class="tab_body">
            <!--Empty tab to make tabs visible before load-->
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="tabs-details">
      <div class="tabs-bg tabs-bg--body"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabsInfo: {
      type: Array,
      default: () => ([]),
      required: false,
    }
  },
  data() {
    return {
      tabs: this.$children || []
    }
  },
  created() {
    this.tabs = this.$children
  },
  computed: {
    selectedTab() {
      return this.$store.state.defaultHomeUrl
    }
  },
  watch: {
    selectedTab(val) {
      if (val) {
        const tempTab = {
          tabName: val[0].toUpperCase() + val.slice(1),
          prefix: val,
          isActive: true
        }

        this.selectTab(tempTab)
      }
    }
  },
  methods: {
    selectTab(selectedTab) {
      this.tabsInfo.forEach((tab)=> {
        tab.isActive = tab.name === selectedTab.tabName
      })
      this.tabs.forEach((tab)=> {
        tab.isActive = tab.name === selectedTab.tabName
      })
      this.$store.commit('SET_DEFAULT_HOME_URL', selectedTab.prefix)
      this.$router.push(`/#${selectedTab.prefix}`)
    }
  }
}
</script>

<style lang="scss">
.tab-wrapper {
  display: block;
  position: relative;
  color: #ffffff;
}

.tabs_list {
  display: flex;
  padding-left: 0 !important;
  align-items: flex-end;

  @include screen('mobile') {
    // position: absolute;
    // top: calc(-100% + 10px);
    display: flex;
    width: 100%;
  }
}

.tabs_list-item {
  margin-right: 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  list-style: none;
  text-transform: capitalize;

  @include screen('mobile') {
    margin-right: 0;
    width: 50%;
  }
}

.tabs-details {
  position: relative;
  border-radius: 0 4px 4px 4px;
  @include screen('mobile') {
    padding-bottom: 92px;
  }
}

.tabs-bg {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);
  height: 100%;
  width: 100%;
  position: absolute;

  @include screen('mobile') {
    background: none;
    backdrop-filter: none;
  }
}

.tabs-bg--tabs {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab-wrapper .tabs-bg {
  background: #1C1C1C;
  opacity: 0.25;
  backdrop-filter: blur(45px);
}

.tab-wrapper.active .tabs-bg {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);
  opacity: 1;
}

.tab-wrapper{
  &.active {
    .tab_body {
      @include screen('mobile') {
        height: 40px;
        padding: 0 15px;
      }
    }
  }
}

.tabs-bg--body {
  border-radius: 0 4px 4px 4px;
}

.tab_icon {
  margin-right: 12px;
}

.tab_title {
  font-family: Open Sans;
  padding-left: toRem(6);
  font-size: 14px;
  font-weight: 600;

  @include screen('mobile') {
    padding-left: 8px;
  }
}

.tab_body {
  padding: toRem(9) toRem(16);
  height: toRem(40);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 8;

  @include screen('mobile') {
    padding: 0 15px;
    height: toRem(40);
    justify-content: center;
  }

  .active & {
    height: toRem(46);

    @include screen('mobile') {
      height: toRem(40);
    }
  }

  img {
    width: 18px;
    height: 18px;

    @include screen('mobile') {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
